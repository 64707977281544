import React from 'react';
import { GET_APP_NAVIGATION_QUERY } from '@cvent/planner-navigation/client';
import { NavMetadata } from '@cvent/planner-navigation/types';
import {
  addApolloCacheProps,
  ApolloCacheProps,
  initApolloClient,
  SchemaLinkApolloClientFactory
} from '@cvent/apollo-client';
import { DocumentNode, InMemoryCache } from '@apollo/client';
import { GetStaticPropsResult } from 'next';
import { schema, context } from './api/graphql';

const navMetadata: NavMetadata = {
  url: '/',
  staticRouteId: '/',
  topParentId: ''
};

// https://nextjs.org/docs/basic-features/data-fetching#write-server-side-code-directly
export const getStaticProps = async (): Promise<GetStaticPropsResult<ApolloCacheProps>> => {
  const client = initApolloClient({}, new SchemaLinkApolloClientFactory({}, schema, context), {
    cache: new InMemoryCache()
  });
  await client.query({
    query: GET_APP_NAVIGATION_QUERY as unknown as DocumentNode,
    variables: { navMetadata }
  });
  const props = addApolloCacheProps(client, {});
  return { props, revalidate: 300 };
};

function IndexPage(): JSX.Element {
  return <h1>Welcomaamma to your CDF project!</h1>;
}

IndexPage.navMetadata = navMetadata;

export default IndexPage;
